.custom-control.custom-switch {
    padding-left: 0;
}

.with-header {
    padding-top: 69px;
    min-height: 800px;
}

.header.navbar {
    padding-left: 15px;
    padding-right: 15px;
}

.card-subtitle {
    margin-bottom: .5rem !important;
}

.docs-article {
    padding-top: 90px !important;
    margin-bottom: 50px;
}

.site-logo a:hover {
    text-decoration: none;
}

.header.navbar .btn {
    padding-top: 8px;
    padding-bottom: 8px;
}

.header.navbar .btn:hover {
    box-shadow: none;
}

.async-button div,
.async-button svg {
    margin: 0 0 0 5px !important;
    vertical-align: middle;
}

.home-page .section {
    margin-bottom: 30px;
}

.media img {
    max-width: 200px;
}

@media (max-width: 640px) {
    .media img {
        max-width: 100px;
    }
}

.markdown .ProseMirror {
    font-family: 'Poppins', sans-serif;
    color: #5d6778;
}

.markdown .ProseMirror blockquote {
    background: #fdf6e5;
    padding: 10px;
}

.footer {
    background-color: lightsteelblue;
}

.footer .footer-col .col-heading,
.footer p,
.footer a {
    color: white;
}